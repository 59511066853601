import Routes from '../../routes';
import { ReactComponent as AdviceIcon } from '../../assets/img/icons/menu-boczne-zporady_BLUE.svg';
import { ReactComponent as BurgerIcon } from '../../assets/img/icons/SafeSafe-dolne-menu-More-white.svg';
import { ReactComponent as FAQIcon } from '../../assets/img/icons/SafeSafe-dolne-menu-FAQ-white.svg';
import { ReactComponent as HomeIcon } from '../../assets/img/icons/SafeSafe-dolne-menu-Home-white.svg';
import { ReactComponent as QRCode } from '../../assets/img/icons/SafeSafe-dolne-menu-qrcode.svg';

export const HomeItem = {
  id: 'home',
  index: 0,
  label: 'Home',
  Icon: HomeIcon,
  path: Routes.Home
};

export const QrCodeItem = {
  id: 'qrcode-scanner',
  index: 1,
  label: 'Kod QR',
  Icon: QRCode,
  openQRScanner: true,
  withModuleBluetoothEnabled: true
};

export const FAQItem = {
  id: 'questions',
  index: 2,
  label: 'Pytania i odp.',
  Icon: FAQIcon,
  path: Routes.FaqPage
};

export const AdviceItem = {
  id: 'advice',
  index: 3,
  label: 'Porady',
  Icon: AdviceIcon,
  path: Routes.AdviceInformation,
  withModuleBluetoothDisabled: true
};

export const BurgerItem = {
  id: 'nav_menu_button',
  index: 4,
  label: 'Więcej',
  Icon: BurgerIcon,
  openMenu: true
};

export const menuItems = [
  HomeItem,
  QrCodeItem,
  FAQItem,
  AdviceItem,
  BurgerItem
];
