import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Settings from './Settings';
import {
  disableBluetoothModule,
  enableBluetoothModule,
  fetchNativeServicesStatus,
  showNativeBatteryOptimizationPermission,
  showNativeBluetoothPermission,
  showNativeLocationPermission
} from '../../store/actions/nativeData';
import { isAndroidWebView } from '../../utills/native';
import useCheckModuleBluetooth from '../../hooks/useCheckModuleBluetooth';

const SettingsContainer = () => {
  const dispatch = useDispatch();
  const {
    servicesStatus: { isBtSupported = false }
  } = useSelector(state => state.nativeData);

  useEffect(() => {
    dispatch(fetchNativeServicesStatus());
  }, [dispatch]);

  const isModuleBluetoothEnable = useCheckModuleBluetooth();

  const toggleChecked = () => {
    if (isModuleBluetoothEnable) {
      dispatch(disableBluetoothModule());
    } else {
      dispatch(showNativeBluetoothPermission());
      if (isAndroidWebView()) {
        dispatch(showNativeLocationPermission());
        dispatch(showNativeBatteryOptimizationPermission());
      }
      dispatch(enableBluetoothModule());
    }
  };

  const items = [
    {
      checked: isModuleBluetoothEnable,
      disabled: !isBtSupported,
      onChange: toggleChecked,
      label: 'Zgoda na używanie Bluetooth w celu wykrycia zagrożeń',
      name: 'bluetooth'
    }
  ];

  return (
    <Settings items={items} title="Ustawienia">
      Korzystamy z Modułu Bluetooth aby móc chronić się wzajemnie i umożliwić
      aplikacji informowanie o zagrożeniach.
    </Settings>
  );
};

export default SettingsContainer;
