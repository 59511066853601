export const DATA_TYPE = {
  FILLED_DIAGNOSIS: 1,
  NOTIFICATION: 2,
  NATIVE_SERVICES_STATUS: 31,
  LOCATION_PERMISSION: 32,
  BT_PERMISSION: 33,
  BATTERY_PERFORMANCE_PERMISSION: 34,
  NOTIFICATION_PERMISSION: 35,
  BT_MODULE: 36,
  CLEAR_BT_DATA: 37,
  TEMP_ID: 41,
  QR_CODE_SCANNING: 42
};
