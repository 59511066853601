import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Imprint } from '../../components';
import Routes from '../../routes';
import Header from '../../components/Header/Header';
import { BottomNavigation } from '../../components/BottomNavigation';
import { InfoCounter } from './components/InfoCounter';

import { Content, Container, View } from '../../theme/grid';
import { Paragraph } from '../../theme/typography';
import { ButtonWrapper, Name, Description } from './UserData.styled';

import IconBT from '../../assets/img/icons/icon-mydata-bt.svg';
import IconCode from '../../assets/img/icons/icon-mydata-code.svg';
import './UserData.scss';

const UserData = () => {
  const history = useHistory();
  const userName = useSelector(state => state.user.name);
  const { successQrCodeScanCounter } = useSelector(state => state.nativeData);

  return (
    <View>
      <Header />
      <Content>
        <Container>
          <Name>{userName},</Name>
          <Paragraph>
            Poniżej znajdują się dane, które podałeś w trakcie rejestracji
            swojego konta.
          </Paragraph>
          <Imprint hidePhoneNumber />
          <ButtonWrapper>
            <Button
              onClick={() => history.push(Routes.UserDataSettings)}
              text="Zarządzaj danymi"
            />
            <Button
              onClick={() => history.push(Routes.UserDataChange)}
              type="secondary"
              text="Zmień dane"
            />
          </ButtonWrapper>
          <Description>
            <InfoCounter icon={IconCode} title="Skanowanie QR kodów">
              Zeskanowałeś bezpośrednio już {successQrCodeScanCounter} kodów
            </InfoCounter>
            <InfoCounter icon={IconBT} title="Moduł Bluetooth">
              W ciągu ostatnich 14 dni Moduł bluetooth zapisał widoczność XXX
              innych anonimowych aplikacji ProteGO Safe
            </InfoCounter>
          </Description>
        </Container>
      </Content>
      <BottomNavigation />
    </View>
  );
};

export default UserData;
