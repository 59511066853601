export const NATIVE_DATA_FETCH_NOTIFICATION_SUCCESS =
  'NATIVE_DATA/GET_NOTIFICATION_SUCCESS';
export const NATIVE_DATA_HIDE_NOTIFICATION_SUCCESS =
  'NATIVE_DATA/HIDE_NOTIFICATION_SUCCESS';
export const NATIVE_DATA_SET_SERVICES_STATUS_SUCCESS =
  'NATIVE_DATA/SET_SERVICES_STATUS_SUCCESS';
export const NATIVE_DATA_FETCH_NATIVE_SERVICES_STATUS_SUCCESS =
  'NATIVE_DATA/FETCH_NATIVE_SERVICES_STATUS_SUCCESS';
export const NATIVE_DATA_SET_QR_CODE_SCAN_SUCCESS =
  'NATIVE_DATA/SET_QR_CODE_SCAN_SUCCESS';
export const NATIVE_DATA_SET_QR_CODE_SCAN_ERROR =
  'NATIVE_DATA/SET_QR_CODE_SCAN_ERROR';
export const NATIVE_DATA_SET_QR_CODE_SCAN_NO_DATA =
  'NATIVE_DATA/SET_QR_CODE_SCAN_NO_DATA';
export const NATIVE_DATA_SET_TEMP_ID = 'NATIVE_DATA/SET_TEMP_ID';
