import styled from 'styled-components';
import { FontWeight } from '../../../../theme/fonts';
import { Color } from '../../../../theme/colors';

export const InfoCounterContainer = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const Icon = styled.div`
  display: flex;
  width: 44px;
  margin-right: 16px;
  img {
    display: block;
    width: 44px;
    height: auto;
  }
`;

export const Title = styled.h1`
  width: calc(100% - 60px);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: ${FontWeight.Bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Color.lightBlack};
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: ${FontWeight.Normal};
  line-height: 1.5;
  color: ${Color.lightBlack};
`;
