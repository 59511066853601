import React from 'react';

import { QRCodeGenerator } from '../QRCodeGenerator';
import {
  TextWrapper,
  QualificationContainer,
  UserName,
  Text,
  QRCodeWrapper,
  IconSmile
} from './Qualification.styled';
import { renderWhenFalse, renderWhenTrue } from '../../helpers/rendering';

const Qualification = ({
  color,
  lastDate,
  userName,
  riskGroup,
  tempId,
  icon
}) => {
  const renderQrCode = renderWhenTrue(() => (
    <QRCodeWrapper>
      <QRCodeGenerator qrCode={tempId} color={color} />
    </QRCodeWrapper>
  ));

  const renderIcon = renderWhenFalse(() => (
    <IconSmile>
      <img src={icon} alt="Smile" />
    </IconSmile>
  ));

  return (
    <QualificationContainer>
      <UserName>{userName},</UserName>
      <TextWrapper>
        <Text>
          <p>
            przeanalizowaliśmy Twoje odpowiedzi. Wynik testu z{' '}
            <strong>{lastDate}</strong> kwalifikuje Cię do grupy:&nbsp;
            <strong>{riskGroup}</strong>.
          </p>
        </Text>
        {renderQrCode(!!tempId)}
        {renderIcon(!!tempId)}
      </TextWrapper>
    </QualificationContainer>
  );
};

export default Qualification;
