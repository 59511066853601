import {
  ONBOARDING_FINISHED,
  QR_CODE_SCREEN_HIDED,
  QR_CODE_SCREEN_SHOWED,
  START_SCREEN_SHOWED
} from '../../types/app';
import {
  NATIVE_DATA_SET_QR_CODE_SCAN_ERROR,
  NATIVE_DATA_SET_QR_CODE_SCAN_NO_DATA,
  NATIVE_DATA_SET_QR_CODE_SCAN_SUCCESS
} from '../../types/nativeData';

const INITIAL_STATE = {
  onboardingFinished: false,
  startScreenShowed: false,
  isQRActive: false,
  qrCodeScanSuccess: undefined
};
const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ONBOARDING_FINISHED:
      return {
        ...state,
        onboardingFinished: true
      };
    case START_SCREEN_SHOWED:
      return {
        ...state,
        startScreenShowed: true
      };
    case QR_CODE_SCREEN_SHOWED:
      return {
        ...state,
        qrCodeScanSuccess: undefined,
        isQRActive: true
      };
    case NATIVE_DATA_SET_QR_CODE_SCAN_NO_DATA:
      return {
        ...state,
        isQRActive: false
      };
    case NATIVE_DATA_SET_QR_CODE_SCAN_SUCCESS:
      return {
        ...state,
        qrCodeScanSuccess: true
      };
    case NATIVE_DATA_SET_QR_CODE_SCAN_ERROR:
      return {
        ...state,
        qrCodeScanSuccess: false
      };
    case QR_CODE_SCREEN_HIDED:
      return {
        ...state,
        isQRActive: false
      };
    default:
      return state;
  }
};

export default appReducer;
