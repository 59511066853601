import React from 'react';
import { useHistory } from 'react-router-dom';
import Routes from '../../routes';
import Header from '../../components/Header/Header';
import { BottomNavigation } from '../../components/BottomNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { ResultView } from '../../components';
import { Container, Content, View } from '../../theme/grid';
import NegativeIcon from '../../assets/img/icons/warning-slim.svg';
import PositiveIcon from '../../assets/img/icons/success-icon.svg';
import { hideScanQrCode, showScanQrCode } from '../../store/actions/nativeData';

const ResultScanningCode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { qrCodeScanSuccess } = useSelector(state => state.app);

  const goHome = () => {
    dispatch(hideScanQrCode());
    history.push(Routes.Home);
  };

  const buttonsResultPositive = [
    {
      text: 'ok',
      onClick: goHome
    }
  ];
  const buttonsResultNegative = [
    {
      text: 'ponów',
      onClick: () => dispatch(showScanQrCode())
    },
    {
      text: 'anuluj',
      onClick: goHome,
      border: true
    }
  ];
  const renderView = (() => {
    if (qrCodeScanSuccess === undefined) {
      return null;
    }
    if (qrCodeScanSuccess) {
      return (
        <ResultView
          title="Dziękujemy za zeskanowanie QR kodu."
          text="Dzięki temu zwiększasz bezpieczeństwo swoje i innych."
          icon={PositiveIcon}
          buttons={buttonsResultPositive}
        />
      );
    }
    if (!qrCodeScanSuccess) {
      return (
        <ResultView
          title="Skanowanie nie powiodło się."
          text="Spróbuj ponownie później."
          icon={NegativeIcon}
          buttons={buttonsResultNegative}
        />
      );
    }
  })();
  return (
    <View>
      <Header hideBackButton />
      <Content>
        <Container className="full-height">{renderView}</Container>
        <BottomNavigation />
      </Content>
    </View>
  );
};

export default ResultScanningCode;
