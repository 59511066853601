import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { Color } from '../../theme/colors';

const QRCodeGenerator = ({ qrCode, color }) => {
  return <QRCode renderAs="svg" value={qrCode} fgColor={color} />;
};

QRCodeGenerator.defaultProps = {
  color: Color.black
};

QRCodeGenerator.propTypes = {
  qrCode: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default QRCodeGenerator;
