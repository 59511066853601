import { useSelector } from 'react-redux';
import { isAndroidWebView, isIOSWebView } from '../../utills/native';

const useCheckModuleBluetooth = () => {
  const { servicesStatus } = useSelector(state => state.nativeData);

  if (!servicesStatus) {
    return false;
  }

  const {
    isBtServiceOn = false,
    isBtOn = false,
    isLocationEnabled = false,
    isBatteryOptimizationOn = true
  } = servicesStatus;

  return (() => {
    if (isIOSWebView()) {
      return isBtServiceOn && isBtOn;
    }

    if (isAndroidWebView()) {
      return (
        isBtServiceOn && isBtOn && isLocationEnabled && !isBatteryOptimizationOn
      );
    }

    return false;
  })();
};

export default useCheckModuleBluetooth;
