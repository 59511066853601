import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Qualification from './Qualification';
import { getLastDate } from '../../utills/calendar';
import { riskGroupResolver } from './QualificationContainer.constants';

const QualificationContainer = () => {
  const { tempId } = useSelector(state => state.nativeData);
  const { name: userName } = useSelector(state => state.user);
  const riskTest = useSelector(state => state.riskTest);
  const { triageLevel } = useSelector(state => state.triage);

  const filledDays = useMemo(() => Object.keys(riskTest), [riskTest]);

  const lastDate = getLastDate(filledDays);

  const { label, icon, color } = riskGroupResolver(triageLevel);

  return (
    <Qualification
      color={color}
      icon={icon}
      lastDate={lastDate}
      riskGroup={label}
      tempId={tempId}
      userName={userName}
    />
  );
};

export default QualificationContainer;
