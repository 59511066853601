import smileGreen from '../../assets/img/icons/buzka-zielona.svg';
import { Color } from '../../theme/colors';
import smileYellow from '../../assets/img/icons/buzka-zolta.svg';
import smileRed from '../../assets/img/icons/buzka-czerwona.svg';
import smileBlue from '../../assets/img/icons/buzka-niebieska.svg';

const RISK_GROUP = {
  no_risk: {
    label: 'Niskie ryzyko infekcji',
    icon: smileGreen,
    color: Color.green
  },
  self_monitoring: {
    label: 'Niskie ryzyko infekcji',
    icon: smileGreen,
    color: Color.green
  },
  quarantine: {
    label: 'Średnie ryzyko infekcji',
    icon: smileYellow,
    color: Color.info
  },
  isolation_call: {
    label: 'Wysokie ryzyko infekcji',
    icon: smileRed,
    color: Color.danger
  },
  isolation_ambulance: {
    label: 'Wysokie ryzyko infekcji',
    icon: smileRed,
    color: Color.danger
  }
};

export const riskGroupResolver = riskGroup => {
  return (
    RISK_GROUP[riskGroup] || {
      label: null,
      icon: smileBlue,
      color: Color.black
    }
  );
};
