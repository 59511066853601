import styled from 'styled-components';
import { FontWeight } from '../../theme/fonts';
import { Color } from '../../theme/colors';

export const Name = styled.h1`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: ${FontWeight.Bold};
  line-height: 1.4;
  color: ${Color.lightBlack};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  margin-top: 30px;
`;
