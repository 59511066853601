import React from 'react';
import PropTypes from 'prop-types';
import {
  Content,
  InfoCounterContainer,
  Icon,
  Wrapper,
  Title
} from './InfoCounter.styled';

const InfoCounter = ({ children, icon, title }) => {
  return (
    <InfoCounterContainer>
      <Wrapper>
        <Icon>
          <img src={icon} alt={title} />
        </Icon>
        <Title>{title}</Title>
      </Wrapper>
      <Content>{children}</Content>
    </InfoCounterContainer>
  );
};

InfoCounter.prototype = {
  children: PropTypes.node,
  icon: PropTypes.string,
  title: PropTypes.string
};

export default InfoCounter;
